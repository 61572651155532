<template>
  <v-app>
    <v-main class="bg-gradient">
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="12"
            md="5"
          >
            <router-link
              :to="{name: 'terms-and-conditions-page'}"
              class="white--text font-weight-bold float-left"
            >
              {{ $vuetify.lang.t('$vuetify.terms_and_conditions') }}
            </router-link>
            <switch-locale />
            <router-view />
            <div class="text-right white--text overline">
              {{ version }}
            </div>
            <div class="white--text text-center mt-5">
              <small>Plaça 18 S.L. - CIF ESB07765845 <br>
                Tel. 971 660 422 – Fax 971 661 922<br>
                C/Des Rafalet, 10 Pol. Son Noguera, 07620 Llucmajor, Mallorca (Illes Balears)</small>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SwitchLocale from './components/SwitchLocale'

export default {
  name: 'App',
  components: { SwitchLocale },
  metaInfo () {
    return {
      titleTemplate: '%s - pl18group TPV'
    }
  },
  computed: {
    version: function () {
      return process.env.VUE_APP_VERSION
    }
  }
}
</script>

<style>
.bg-gradient {
  background-color: #0093E9;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
}
</style>
