import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from './locales/es.ts'
import en from './locales/en.ts'
import ca from './locales/ca.ts'

Vue.use(Vuetify)

export default new Vuetify({
  defaultAssets: {
    font: {
      family: 'Libre Baskerville'
    }
  },
  lang: {
    locales: {
      es,
      en,
      ca
    },
    current: 'es'
  }
})
