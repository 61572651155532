import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import VeeValidate, { Validator } from 'vee-validate'
import es from 'vee-validate/dist/locale/es'

Vue.use(require('vue-moment'))
Vue.use(VeeValidate)
Validator.localize('es', es)

Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
