import es from 'vuetify/lib/locale/es'

export default {
  ...es,
  welcome: 'Bienvenido',
  platform: 'Esta es nuestra plataforma de pagos con tarjeta. Recibirás por email tu acceso con los datos necesarios para realizar el pago.',
  process_payment: 'Procesar pago',
  proceed: 'Proceder al pago con tarjeta de la cantidad',
  pay_for_reference: 'por la referencia',
  doubts: '¿Alguna duda? Llámanos',
  pay_for: 'Pagado en nombre de',
  payment_limit: 'Fecha límite de pago',
  payed_successfully: 'Pagado satisfactoriamente',
  payment_error: 'Error al procesar el pago',
  pay: 'Pagar',
  page_does_not_exist: 'La página no existe.',
  terms_and_conditions: 'Condiciones de venta'
}
