<template>
  <div class="my-2 text-right">
    <img
      v-for="lang in langs"
      :key="lang.locale"
      :alt="lang.text"
      :src="`${lang.src}`"
      height="20"
      @click="changeLocale(lang.locale)"
      class="ml-4"
      style="cursor: pointer"
    >
  </div>
</template>

<script>
export default {
  name: 'SwitchLocale',
  data: () => ({
    langs: {
      es: {
        locale: 'es',
        text: 'Español',
        src: require('../assets/svg-country-flags/es.svg')
      },
      ca: {
        locale: 'ca',
        text: 'Català',
        src: require('../assets/svg-country-flags/ca.svg')
      },
      en: {
        locale: 'en',
        text: 'English',
        src: require('../assets/svg-country-flags/gb.svg')
      }
    }
  }),
  methods: {
    changeLocale (locale) {
      this.$validator.localize(locale)
      this.$vuetify.lang.current = locale
    }
  }
}
</script>

<style scoped>

</style>
