import Vue from 'vue'
import VueRouter from 'vue-router'

const HomePage = () => import(/* webpackChunkName: "essentials" */ '../views/HomePage')
const PaymentPage = () => import(/* webpackChunkName: "essentials" */ '../views/PaymentPage')
const TermsAndConditions = () => import(/* webpackChunkName: "essentials" */ '../views/TermsAndConditionsPage')
const Error404 = () => import(/* webpackChunkName: "essentials" */ '../views/Error404')
const PaymentError = () => import(/* webpackChunkName: "essentials" */ '../views/PaymentError')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: HomePage
  },
  {
    path: '/payment/:website/:token',
    name: 'payment-page',
    component: PaymentPage
  },
  {
    path: '/payment-error',
    name: 'payment-error-page',
    component: PaymentError
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions-page',
    component: TermsAndConditions
  },
  {
    path: '/error404',
    name: 'error-404-page',
    component: Error404
  },
  {
    path: '*',
    redirect: '/error404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
