import ca from 'vuetify/lib/locale/ca'

export default {
  ...ca,
  welcome: 'Benvingut',
  platform: 'Aquesta es la nostra plataforma de pagaments amb tarjeta. Rebràs per email el teu accés amb les dades necessaries per a realitzar el pagament.',
  process_payment: 'Processar pagament',
  proceed: 'Procedir al pagament amb tarjeta de la quantitat',
  pay_for_reference: 'per la referència',
  pay_for: 'Pagat a nom de',
  payment_limit: 'Data límit de pagament',
  doubts: 'Algún dubte? Crida\'ns',
  pay: 'Pagar',
  payed_successfully: 'Pagat satisfactòriament',
  payment_error: 'Error al processar el pagament',
  page_does_not_exist: 'La pagina no existeix',
  terms_and_conditions: 'Condicions de venda'
}
